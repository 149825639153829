import {PermissionBase} from './admin-permission-base';
import {AdminRole} from '../../enum/admin-permission.enum';
import {SuperAdminPermission, OperationStaffPermission, Contractors, OperationsTrainee} from './admin-role-permission';
import {SessionService} from '../../../../services/session-service';

export class PermissionsFactory {
  static session: SessionService;
  public static instance: PermissionBase;

  constructor() {
  }

  public static getInstance() {
    if (this.instance) {
      return this.instance;
    } else {
      let role = 'AdminL0' as AdminRole;
      let roleList = '';
      for (const group of this.session.getUser().groupList) {
        roleList += group + ',';
      }
      if (roleList.includes('L3')) {
        role = 'AdminL3' as AdminRole;
      } else if (roleList.includes('L2')) {
        role = 'AdminL2' as AdminRole;
      } else if (roleList.includes('L1')) {
        role = 'AdminL1' as AdminRole;
      } else if (roleList.includes('L0')) {
        role = 'AdminL0' as AdminRole;
      }
      switch (role) {
        case AdminRole.Level3:
          this.instance = new SuperAdminPermission();
          break;
        case AdminRole.Level2:
          this.instance = new OperationStaffPermission();
          break;
        case AdminRole.Level1:
          this.instance = new Contractors();
          break;
        case AdminRole.Level0:
          this.instance = new OperationsTrainee();
          break;
        default:
          this.instance = new OperationsTrainee();
          break;
      }
    }
  }
  static setSession(session: SessionService) {
    PermissionsFactory.session = session;
  }

  static getAdminUserLevel(): string{
    if(this.session.isUserLoggedIn){
      let role = 'AdminL0' as AdminRole;
      let roleList = '';
      for (const group of this.session.getUser().groupList) {
        roleList += group + ',';
      }
      if (roleList.includes('L3')) {
        role = 'AdminL3' as AdminRole;
      } else if (roleList.includes('L2')) {
        role = 'AdminL2' as AdminRole;
      } else if (roleList.includes('L1')) {
        role = 'AdminL1' as AdminRole;
      } else if (roleList.includes('L0')) {
        role = 'AdminL0' as AdminRole;
      }
      return role;

    }
    return '';
  }
}
