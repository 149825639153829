/* eslint-disable object-shorthand */
import {Injectable} from '@angular/core';
import {NavigationEnd} from '@angular/router';
import {environment} from '../../environments/environment';
import {Program} from '../models/program/program';
import {Show} from '../models/program/show';
import { HydratedUser } from '../models/account/dto/hydrated-user';
import { Ecommerce } from '../models/account/dto/ecommerce-payment';

// eslint-disable-next-line @typescript-eslint/ban-types
declare let gtag: Function;

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {

  constructor() {
  }

  public trackEvent(
    eventCategory: string,
    eventAction: string,
    eventLabel: string = null,
    eventValue: number = null) {
    gtag('event', eventAction, {
      event_category: eventCategory,
      event_label: eventLabel,
      value: eventValue,
      debug_mode: !environment.production
    });
  }

  public trackAuthFlow(event: string, userId: string,loginStatus: string){
      gtag('ga4',event,{
        event: event,
        user_id: userId,
        login_status: loginStatus,
        debug_mode: !environment.production
      });

  }



  public trackPaymentModule(event: string, ecommerce?: Ecommerce, argsMap?: Map<string, string>) {
    const eventData = {
      event: event,
      debug_mode: !environment.production
    };

    if (!!argsMap) {
      argsMap.forEach((value, key) => {
        eventData[key] = value;
      });
    }

    const eventOptions = {
      ecommerce: ecommerce,
      ...eventData
    };

    gtag('ga4', event, eventOptions);
  }


  public trackSignUpFlow(event: string, signUpStep: string,
    loginStatus?: string, accountType?: string, userId?: string, argsMap?: Map<string, string>) {

    const eventData = {
      event: event,
      signup_step: signUpStep,
      debug_mode: !environment.production
    };

    if (!!loginStatus && !!accountType) {
      Object.assign(eventData, {
        account_type: accountType,
        login_status: loginStatus
      });

      gtag('ga4', event,eventData);
    } else {
      if (!!argsMap) {
        argsMap.forEach((value, key) => {
          eventData[key] = value;
        });
      }

      gtag('ga4', event,eventData);
    }
  }


  public trackVideoEvent(program: Program|Show, action: 'play' | 'pause',
      streamType: string) {
    const videoVenue = (program as Program)?.venue;
    const videoEvent: string = null; //(program as Program)?.event; // TODO add event name here
    // gtag('config', environment.googleAnalyticsTackingCode, {
    //   custom_map: {
    //     dimension1: 'video_action',
    //     dimension2: 'video_league',
    //     dimension3: 'video_venue',
    //     dimension4: 'video_event'
    //   }
    // });
    gtag('event', 'video', {
      video_url: program?.playbackStreamUrl,
      video_title: program?.getGoogleAnalyticsEventTitle(),
      video_action: action,
      debug_mode: !environment.production,
      video_league: program.league ?? undefined,
      video_venue: videoVenue ?? undefined,
      video_event: videoEvent ?? undefined,
      stream_type: streamType

    });
  }

  public trackPathChange(event: NavigationEnd,user: HydratedUser) {
    gtag('ga4',event,{
      page_path:event.urlAfterRedirects,
      user_id: !!user ? user.id.toString() : undefined,
      login_status: !!user ? 'logged_in' : 'not_logged_in',
      debug_mode: !environment.production
    });

    gtag('config', environment.googleAnalyticsTackingCode,
    {
      page_path: event.urlAfterRedirects
      , debug_mode: !environment.production,
      user_id: !!user ? user.id.toString() : undefined,
      login_status: !!user ? 'logged_in' : 'not_logged_in'
    });
  }

}

