import {Injectable} from '@angular/core';
import {BaseViewModel} from '../../../models/base/base-view-model';
import {LoadingOptions} from '../../../models/shared/loading-options';
import {ResourceDomainModel} from '../../../domainModels/resource-domain-model';
import {BehaviorSubject, Observable} from 'rxjs';
import {CameraSystem} from '../../../models/resources/camera-system';
import {DatatableData} from '../../../models/protocols/datatable-data';
import {ActivatedRoute, Router} from '@angular/router';
import {map, switchMap} from 'rxjs/operators';
import {indicateOnNext} from '../../../utils/observable.extensions';
import {ToastService} from '../../../services/toast-service';
import { CameraStream } from 'src/app/models/resources/camera-stream';

@Injectable()
export class CameraSystemViewModal extends BaseViewModel {

  loadingOpts: LoadingOptions = LoadingOptions.defaultLight(false, false);
  refreshSystemSubject$ = new BehaviorSubject<void>(null);
  addNewSystem$ = this.activatedRoute.data.pipe(map(data => data.addSystem as boolean));
  systemId$ = this.activatedRoute.params.pipe(map(params => params.cameraSystemId));
  system$: Observable<any> = this.systemId$.pipe(
    switchMap(systemId => this.getCameraSystemById(systemId))
  );

  constructor(
    private domainModel: ResourceDomainModel,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    private toastService: ToastService,
  ) {
    super();
    this.init();
  }

  init() {
    super.init();
  }

saveCameraSystem(formObject: CameraSystem) {
    this.domainModel.saveCameraSystem(formObject)
      .pipe(indicateOnNext(this.loadingOpts, $localize`Saving Camera System`))
      .subscribe(() => {
        if (!!formObject.id) {
          this.toastService.publishSuccessMessage($localize`Camera System Updated`, null);
          this.refreshSystemSubject$.next();
          this.router.navigate(['..'], {relativeTo: this.activatedRoute}).then();
        } else {
          this.toastService.publishSuccessMessage($localize`Camera System Created`, null);
          this.router.navigate(['..'], {relativeTo: this.activatedRoute}).then();
        }
      }, error => {
        this.toastService.publishError(error);
      });
    //this.updateCameraSystemTypeConfigurations(formObject.id, formObject.config).subscribe();
  }

  getCameraSystems(): Observable<CameraSystem[]> {
    return this.domainModel.getCameraSystems();
  };

  getCameraSystemById(cameraSystemId: string): Observable<CameraSystem> {
    return this.domainModel.getCameraSystemById(cameraSystemId);
  }

  getCameraSystemTypeConfigurations(cameraSystemTypeId: string): Observable<CameraSystem> {
    return this.domainModel.getCameraSystemTypesConfigurations(cameraSystemTypeId);
  }

  rowClicked(data: DatatableData) {
    const cameraSystem = data as CameraSystem;
    this.router.navigate([`./${cameraSystem.id}`], {relativeTo: this.activatedRoute,
        queryParams: { forceUpdate: true }
      }).then();
}
  addNewSystem() {
    this.router.navigate([`./add`], {relativeTo: this.activatedRoute}).then();
  }

  updateCameraSystemTypeConfigurations(cameraSystemTypeId: string, req: any): Observable<CameraSystem> {
    return this.domainModel.updateCameraSystemTypeConfigurations(cameraSystemTypeId, req);
  }
  getCameraStreams(isLive: boolean): Observable<CameraStream[]>{
    return this.domainModel.getCameraStreamDetails(isLive);
  }

}
